import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import * as cookie from "cookie";
import { BlackPageLayer, Popup } from "../components/common/black-bg-layer";
import { IFooterLink, SocialMediaItem } from "../components/common/footer";
import { IApp } from "./_app";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap";
import { getLockscreenPageDetails } from "../services/lockscreen";
import { Seo } from "../components/common/seo";
import Head from "next/head";
import { loadCategoryWidgets } from "../utils/homepageUtils";
import { useRouter } from "next/router";
import { setCookie } from "../utils/cookies";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import dynamic from "next/dynamic";
import ssrCache from "../cache/SsrCache";

const CacheTTL = 3600000;

const JapanDesktop = dynamic(() =>
  import("../components/lockscreen/regions/japan/desktop").then((module) => ({
    default: module.LockscreenJapanDesktop,
  }))
);
const JapanMobile = dynamic(() =>
  import("../components/lockscreen/regions/japan/mobile").then((module) => ({
    default: module.LockscreenJapanMobile,
  }))
);

const JapanJapaneseDesktop = dynamic(() =>
  import("../components/lockscreen/regions/japan-japanese/desktop").then(
    (module) => ({ default: module.LockscreenJapaneseDesktop })
  )
);
const JapanJapaneseMobile = dynamic(() =>
  import("../components/lockscreen/regions/japan-japanese/mobile").then(
    (module) => ({ default: module.LockscreenJapaneseMobile })
  )
);

const IndonesiaDesktop = dynamic(() =>
  import("../components/lockscreen/regions/indonesia/desktop").then(
    (module) => ({ default: module.IndonesiaLockscreenDesktop })
  )
);
const IndonesiaMobile = dynamic(() =>
  import("../components/lockscreen/regions/indonesia/mobile").then(
    (module) => ({ default: module.IndonesiaLockscreenMobile })
  )
);

const BahasaDesktop = dynamic(() =>
  import("../components/lockscreen/regions/indonesia-bahasa/desktop").then(
    (module) => ({ default: module.LockscreenBahasaDesktop })
  )
);
const BahasaMobile = dynamic(() =>
  import("../components/lockscreen/regions/indonesia-bahasa/mobile").then(
    (module) => ({ default: module.LockscreenBahasaMobile })
  )
);

const UsDesktop = dynamic(() =>
  import("../components/lockscreen/regions/us/desktop").then((module) => ({
    default: module.UsLockscreenDesktop,
  }))
);
const UsMobile = dynamic(() =>
  import("../components/lockscreen/regions/us/mobile").then((module) => ({
    default: module.UsLockscreenMobile,
  }))
);

const IndiaDesktop = dynamic(() =>
  import("../components/lockscreen/regions/india/desktop").then((module) => ({
    default: module.IndiaLockscreenDesktop,
  }))
);
const IndiaMobile = dynamic(() =>
  import("../components/lockscreen/regions/india/mobile").then((module) => ({
    default: module.IndiaLockscreenMobile,
  }))
);

const GlobalDesktop = dynamic(() =>
  import("../components/lockscreen/regions/common/desktop").then((module) => ({
    default: module.LockscreenDesktop,
  }))
);
const GlobalMobile = dynamic(() =>
  import("../components/lockscreen/regions/common/mobile").then((module) => ({
    default: module.LockscreenMobile,
  }))
);

gsap.registerPlugin(ScrollTrigger);

export interface FooterDetails {
  company: IFooterLink[];
  copyRIghtText: string;
  createdAt: string;
  published: string;
  updatedAt: string;
  legal: IFooterLink[];
  privacySeal: string;
  socialIcons: SocialMediaItem[];
}

interface LockscreenProps extends IApp {
  lockscreenData: any;
  isIndiaPage?: boolean;
  userRegion?: string;
  locale?: string;
  enableHomePopup?: boolean;
}

export const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

export default function Home(props: LockscreenProps) {
  const router = useRouter();
  let sectionIds =
    props.lockscreenData?.attributes?.customScrollSectionIds?.sectionIds;
  let currentSection = 0;
  let currentIndex = -1;
  let isScrolling = false;
  let scrollTimeout;
  let endY = 0;
  let startY = 0;
  let startX = 0;
  let lastDirection = 1;
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const valuePropsRef = useRef(null);
  const [isDesktopView, setIsDesktopView] = useState<boolean>();
  const [openPopup, setOpenPopup] = useState(
    props.lockscreenData?.attributes?.largerFontCSS &&
      (props.enableHomePopup ? true : false) &&
      props.userRegion !== "us"
  );

  console.log("OpenP", openPopup);

  useEffect(() => {
    props.handleDisplayHeader(true);
    props.handleDisplayFooter(true);
    const beforeUnloadHandler = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener("load", beforeUnloadHandler);
    return () => {
      window.window.removeEventListener("load", beforeUnloadHandler);
    };
  }, []);

  useEffect(() => {
    let doc = document.querySelector(".section");
    setIsVisible2(true);
    if (window.innerWidth < 1060) {
      if ("ontouchstart" in window) {
        doc?.addEventListener("touchstart", touchStartHandler);
        doc?.addEventListener("touchmove", touchMoveHandler, {
          passive: false,
        });
        doc?.addEventListener("touchend", touchEndHandler);
      }
    }
    return () => {
      doc?.removeEventListener("touchend", touchEndHandler);
      doc?.removeEventListener("touchstart", touchStartHandler);
      doc?.removeEventListener("touchmove", touchMoveHandler);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth > 1060) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      });

      if (valuePropsRef.current) {
        observer.observe(valuePropsRef.current);
      }
    }
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      if (window.innerWidth < 1060) {
        setIsDesktopView(false);
      } else {
        setIsDesktopView(true);
      }
    }
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const touchMoveHandler = useCallback(function (event: any) {
    event.preventDefault();
    endY = event.touches[0].clientY;
  }, []);

  const touchStartHandler = useCallback(function (event: any) {
    startY = event.touches[0].clientY;
    startX = event.touches[0].clientX;
  }, []);

  const touchEndHandler = useCallback(function (event: any) {
    let endDistance = event.changedTouches[0].clientY;
    let distanceY = endDistance - startY;

    const endHorizontal = event.changedTouches[0].clientX;
    const distanceHorizontal = endHorizontal - startX;

    if (distanceHorizontal <= 50 && distanceHorizontal >= -50) {
      if (distanceY <= -10) {
        onScrollMobile({ deltaY: 1 });
      } else if (distanceY >= 10) {
        onScrollMobile({ deltaY: -1 });
      }
    } else {
      //left or right swipw logic
    }
  }, []);

  const onScrollMobile = (event: any) => {
    /*
      This function contains specialized scroll logic, specifically tailored for mobile devices. 
      We decided to implement custom scrolling exclusively for mobile views due to issues with GSAP 
      pinning functionality, which was not functioning correctly during mobile scrolling, causing excessively
       fast scrolling behavior.
    */
    if (!isScrolling) {
      let currentPosition = window.pageYOffset;
      let targetPosition;
      let innHeight = window.innerHeight;
      let test: any | null;
      let currentSectionValue = sectionIds[currentSection].scroll;
      let timeout = currentSectionValue[currentIndex] === "" ? 500 : 500;
      isScrolling = true;
      if (event.deltaY > 0) {
        /*
          top to bottom logic
        */
        if (
          (currentSection === 6 &&
            currentIndex === 2 &&
            router.locale != "id") ||
          (currentSection == 10 && router.locale != "id")
        ) {
          /*
          To prevent users from scrolling down rapidly in the autoplay sections, 
          we've introduced a 2000ms scroll delay. This ensures a controlled scrolling
          experience in those sections.
          */
          timeout = 2000;
        }

        if (
          sectionIds[currentSection].scroll.length === 0 ||
          currentIndex === sectionIds[currentSection].scroll.length - 1
        ) {
          // console.log("current section :" + sectionIds[currentSection].section);
          // console.log("current IDx: " + currentIndex);
          /*
          If the current section contains only one tile or if the section has reached the last tile, 
          we will proceed to the next section. The navigation between sections will be accomplished using the
          next section id or prev section id based on the designated IDs for each section.
          */
          currentSection = Math.min(currentSection + 1, sectionIds.length - 1);
          test = document.getElementById(sectionIds[currentSection].section);
          test?.scrollIntoView({ behavior: "smooth" });
          currentIndex = -1;
        } else if (currentIndex < sectionIds[currentSection].scroll.length) {
          currentIndex++;
          targetPosition = Math.floor(currentPosition + innHeight);
          window.scrollTo({
            top: targetPosition,
            left: 0,
            behavior: "smooth",
          });
        }
        // currentIndex = Math.min(currentIndex + 1, sectionIds.length);
        // test = document.getElementById(sectionIds[currentIndex]);
        // targetPosition = Math.floor(currentPosition + innHeight);
        lastDirection = 1;
      } else {
        /*
          bottom to top logic
        */
        if (currentSection === 7 && currentIndex === 7) {
          currentSection = 6;
          currentIndex = 3;
          test = document.getElementById(sectionIds[currentSection].section);
          test?.scrollIntoView({ behavior: "smooth" });
        } else if (currentIndex === -1) {
          currentSection = Math.max(currentSection - 1, 0);
          test = document.getElementById(sectionIds[currentSection].section);
          test?.scrollIntoView({ behavior: "smooth" });
          currentIndex = sectionIds[currentSection].scroll.length - 1;
        } else {
          currentIndex = Math.max(currentIndex - 1, -1);
          targetPosition = Math.floor(currentPosition - innHeight);
          window.scrollTo({
            top: targetPosition,
            left: 0,
            behavior: "smooth",
          });
        }
        lastDirection = -1;
      }
      scrollTimeout = setTimeout(function () {
        isScrolling = false;
      }, timeout);
    }
  };

  useEffect(() => {
    let timer: any;
    if (openPopup) {
      timer = setTimeout(() => {
        setOpenPopup(false);
        setCookie("isHomePopupDisplayed", "true", 1);
      }, 30000);
    }
    return () => clearTimeout(timer);
  }, [openPopup]);
  return (
    <BlackPageLayer className="section" minHeight={"100vh"}>
      <Seo
        title={props.lockscreenData?.attributes?.seo?.title}
        description={props.lockscreenData?.attributes?.seo?.description}
        canonical={props.lockscreenData?.attributes?.seo?.canonicalTag}
        hreflang={props.lockscreenData?.attributes?.seo?.hreflang}
      />
      <Head>
        {/* {loadCategoryWidgets()} */}
        <script
          dangerouslySetInnerHTML={{
            __html: `history.scrollRestoration = "manual"`,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
                        (function (l, i, n, k, t, r, ee) {
                            l[t] = l[t] || function () { (l[t].q = l[t].q || []).push(arguments) },
                            l[t].l = 1 * new Date(); r = i.createElement(n); ee = i.getElementsByTagName(n)[0];
                            r.async = 1; r.src = k; ee.parentNode.insertBefore(r, ee)
                        })
                        (window, document, 'script', 'https://assets.production.linktr.ee/ltpixel/ltpix.min.js?t=' + 864e5*Math.ceil(new Date/864e5), 'lti')
                    `,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
                        lti('init', 'LTU-ccb1a4b4-3535-435d-99c8-d54e274fb4f0')
                        lti('pageloaded')
                    `,
          }}
        />
      </Head>
      <h1 className="hidden-heading">
        {props.lockscreenData?.attributes?.seo?.title}
      </h1>
      {/*
          To facilitate smooth and efficient handling of advanced GSAP animation ,
          we have developed separate React components for desktop and mobile views. 
          This approach allows us to cater to the specific requirements and intricacies of each platform effectively.
          Also now for each region we will have different components as the structure and ordering of components will change according to region.
        */}
      {props.locale === "en" ? (
        isDesktopView ? (
          <IndiaDesktop data={props.lockscreenData} />
        ) : (
          <IndiaMobile data={props.lockscreenData} />
        )
      ) : (
        <></>
      )}
      {props.locale === "id-ID" ? (
        isDesktopView ? (
          <IndonesiaDesktop data={props.lockscreenData} />
        ) : (
          <IndonesiaMobile data={props.lockscreenData} />
        )
      ) : (
        <></>
      )}
      {props.locale === "id" ? (
        isDesktopView ? (
          <BahasaDesktop data={props.lockscreenData} />
        ) : (
          <BahasaMobile data={props.lockscreenData} />
        )
      ) : (
        <></>
      )}
      {props.locale === "jp" ? (
        isDesktopView ? (
          <JapanJapaneseDesktop data={props.lockscreenData} />
        ) : (
          <JapanJapaneseMobile data={props.lockscreenData} />
        )
      ) : (
        <></>
      )}
      {props.locale === "ja-JP" ? (
        isDesktopView ? (
          <JapanDesktop data={props.lockscreenData} />
        ) : (
          <JapanMobile data={props.lockscreenData} />
        )
      ) : (
        <></>
      )}

      {props.locale === "us" ? (
        isDesktopView ? (
          <UsDesktop data={props.lockscreenData} />
        ) : (
          <UsMobile data={props.lockscreenData} />
        )
      ) : (
        <></>
      )}

      {props?.locale == "row" ? (
        isDesktopView ? (
          <GlobalDesktop data={props.lockscreenData} />
        ) : (
          <GlobalMobile data={props.lockscreenData} />
        )
      ) : (
        <></>
      )}

      {openPopup && (
        <Popup>
          <div className="popup-container">
            <div
              className="close-icon"
              onClick={() => {
                setOpenPopup(false);
                setCookie("isHomePopupDisplayed", "true", 1);
              }}
            >
              <img src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/lock-screen-report-close.png" />
            </div>

            <div
              className="popup-body"
              dangerouslySetInnerHTML={{
                __html: props.lockscreenData?.attributes?.largerFontCSS,
              }}
            ></div>
          </div>
        </Popup>
      )}
    </BlackPageLayer>
  );
}

export async function getServerSideProps({ locale, req }: any) {
  try {
    let pageResponse: any;
    const parsedCookies = cookie.parse(req.headers.cookie || "");
    console.log("Page locale: ", locale);
    const enableHomePopup = parsedCookies["isHomePopupDisplayed"]
      ? false
      : true;

    let userRegion = parsedCookies.userRegion || "row";
    let key = `homepage:${locale}`;
    if (!ssrCache.has(key)) {
      console.log("Getting from cms");
      pageResponse = await getLockscreenPageDetails(locale);
      ssrCache.set(key, pageResponse, { ttl: CacheTTL });
    } else {
      console.log("getting cached response");
      pageResponse = ssrCache.get(key);
    }
    return {
      props: {
        lockscreenData: pageResponse.data,
        userRegion,
        locale,
        enableHomePopup,
        ...(await serverSideTranslations(locale, ["common"])),
      },
    };
  } catch (e) {
    console.log(e);
    return {
      props: {
        lockscreenData: {},
      },
    };
  }
}
